<template>
  <div class="home">
    <div class="auth-form">
      <div class="flex">
        <div class="auth-empty">
          <auth-left></auth-left>
        </div>
        <div class="form">
           <div class="form-controller">
              <form @submit.prevent="submit" class="form-control__content">
                <h2 class="cente">Reset password</h2>
                  <b-field class="mt-5" label="Email address" :label-position="'on-border'">
                      <b-input type="email" v-model="form.email" size="is-medium" required></b-input>
                  </b-field>
                   <br>
                  <b-button native-type="submit" type="is-primary" size="is-medium"  expanded>Sign in</b-button>
                  <p class="center mt-5"><router-link to="/login">Take me back</router-link></p>
              </form>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import AuthLeft from '@/components/AuthLeft.vue';

export default {
  components: { AuthLeft },
  name: 'Home',
  data: () => ({
    form: {},
  }),
  methods: {
    submit(){
        firebase.auth().sendPasswordResetEmail(this.form.email).then(user => {
            if(user == undefined){
                this.hasError('Reset instructions has been sent to this email address.', 'is-success')
            }
        }).catch((err) => {
            this.hasError(err.message, 'is-warning')
        })
    },
    hasError(msg, kind){
        this.$buefy.toast.open({
            message: msg,
            type: kind
        })
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/style.scss';





</style>
